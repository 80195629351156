import Api from '@/services/Api';
import store from '@/store';
//User api cals
export default {
  getAllQuestionGroups() {
    return Api().get('/powerguard/questiongroups', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  saveQuestionGroupModification(credintials) {
    console.log(credintials);
    return Api().patch('/powerguard/questiongroup', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  addNewQuestionGroup(credintials) {
    return Api().post('/powerguard/questiongroup', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};
