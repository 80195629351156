import Api from '@/services/Api';
import store from '@/store';
//User api cals
export default {
  getAllQuestions() {
    return Api().get('/powerguard/questions', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  saveQuestionModification(credintials) {
    console.log(credintials);
    return Api().patch('/powerguard/question', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  addNewQuestion(credintials) {
    return Api().post('/powerguard/question', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};
