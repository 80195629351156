<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left><h4>Lisää keskustelu</h4></v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  label="Otsikko"
                  prepend-icon="mdi-account"
                  :rules="[rules.required]"
                  v-model="addDiscussionValues.discussionTitle"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  label="Kuvaus keskustelusta"
                  prepend-icon="mdi-information-variant"
                  v-model="addDiscussionValues.discussionDescription"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addDiscussionValues.discussionOrganizationId"
                  :items="organizations"
                  item-text="organizationName"
                  item-value="organizationId"
                  label="Organisaatio"
                  placeholder="Valitse"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addDiscussionValues.discussionModerator"
                  :items="users"
                  item-text="userFirstName"
                  item-value="uuid"
                  label="Valitse moderaattori"
                  placeholder="Valitse"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addDiscussionValues.discussionState"
                  :items="queryState"
                  item-text="name"
                  item-value="value"
                  label="Kyselyn tila"
                  placeholder="Valitse"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewDiscussion"
                >
                  Lisää uusi keskustelu
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import UserService from '@/services/UserService.js';
import OrganizationService from '@/services/OrganizationService.js';
import DiscussionService from '@/services/PowerGuard/DiscussionService.js';
export default {
  data() {
    return {
      queryState: [
        { name: 'Avoin', value: 'Avoin' },
        { name: 'Suljettu', value: 'Suljettu' },
      ],
      organizations: [],
      users: [],
      addDiscussionValues: {},
      message: null,
      error: null,
      rules: {
        required: (value) => !!value || 'Pakollinen tieto',
      },
    };
  },
  async created() {
    try {
      const response = await OrganizationService.getAllOrganizations();
      this.organizations = response.data.organizations;
    } catch (error) {
      this.error = error;
    }
    try {
      const response = await UserService.getAllUsers();
      this.users = response.data.users;
    } catch (error) {
      this.error = error;
    }
  },

  methods: {
    async addNewDiscussion() {
      try {
        const response = await DiscussionService.addNewDiscussion(
          this.addDiscussionValues
        );
        this.addDiscussionValues = {};
        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
