import Api from '@/services/Api';
import store from '@/store';
//User api cals
export default {
  getAllFeedbacks() {
    return Api().get('/powerguard/feedbacks/', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  saveQueryModification(credintials) {
    return Api().patch('/powerguard/query', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  addNewFeedback(credintials) {
    return Api().post('/powerguard/feedback/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  getQueryByCustomerId(credintials) {
    return Api().post('/powerguard/query-customer-id/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};
