import Api from '@/services/Api';
import store from '@/store';
//Auhetication api cals
export default {
  register(credintials) {
    return Api().post('register', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  login(credintials) {
    return Api().post('login', credintials);
  },
};

// AuthenticationService.register({
//     email:'testi@testi.fi',
//     passsword: '1234455'
// })
