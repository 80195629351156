<!-- Top and sidebar template -->
<template>
  <nav>
    <v-toolbar color="primary" dark>
      <v-avatar>
        <v-img
          src="https://randomuser.me/api/portraits/thumb/men/75.jpg"
        ></v-img>
      </v-avatar>

      <v-toolbar-title class="ml-5">{{ user.userEmail }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn text @click="logout">
        <v-icon right>mdi-export</v-icon>
      </v-btn>
    </v-toolbar>
    <!--NAvigointi drawer alkaa -->
  </nav>
</template>

<script>
//import Popup from './Popup.vue';
export default {
  data: () => ({
    user: [],
    name: 'navbar',
  }),
  //When page is loaded
  created() {
    this.user = this.$store.state.user;
  },
  methods: {
    /**
     * User click logout button
     * Set token null
     * Set user null
     * */
    logout() {
      this.$store.dispatch('setToken', null);
      this.$store.dispatch('setUser', null);
      this.$router.push({
        name: 'login',
      });
    },
  },
};
</script>

<style coped></style>
