<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form @submit.prevent="sendFile" enctype="multipart/form-data">
          <v-flex xs12 md12 text-left><h4>Lisää materiaalia</h4></v-flex>
          <v-flex xs12 md12>
            <v-text-field
              label="Otsikko"
              prepend-icon="mdi-account"
              v-model="materialbank.materialbankTitle"
              autocomplete="off"
            />
          </v-flex>
          <v-flex xs12 md12>
            <v-file-input
              label="Valitse tiedosto"
              v-model="selectedFile"
            ></v-file-input>
          </v-flex>
          <v-btn type="submit" class="mx-0 font-weight-light" color="general"
            >Lähetä</v-btn
          >
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import MaterialbankService from '@/services/PowerGuard/MaterialbankService';
export default {
  name: 'AddFile',

  data() {
    return {
      selectedFile: null,
      materialbank: {},
      message: null,
      error: null,
    };
  },
  methods: {
    async sendFile() {
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);
      formData.append('title', this.materialbank.materialbankTitle);
      formData.append(
        'organizationId',
        this.$store.state.user.userOrganization
      );
      try {
        const response = await MaterialbankService.saveFile(formData);
        this.organizations = response.data.organizations;
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>

<style></style>
