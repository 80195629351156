<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card :elevation="hover ? 24 : 6" class="mb-4 pa-2">
              <v-card-title class="headline">
                {{ queryTitle }}
              </v-card-title>

              <v-card-subtitle>{{ queryDescription }}</v-card-subtitle>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" v-for="(question, index) in questions" :key="index">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card :elevation="hover ? 24 : 6" class="mb-2 pa-2">
              <v-card-title> </v-card-title>

              <v-card-text
                ><h3>
                  {{ question.questionDescription }}
                </h3></v-card-text
              >

              <v-card-actions>
                <v-list-item class="grow">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-radio-group v-model="answers[question.id]">
                        <v-radio label="Täysin eri mieltä" value="1"></v-radio>
                        <v-radio
                          label="Osittain eri mieltä"
                          value="2"
                        ></v-radio>
                        <v-radio label="Ihan ok" value="3"></v-radio>
                        <v-radio
                          label="Osittain samaa mieltä"
                          value="4"
                        ></v-radio>
                        <v-radio
                          label="Täysin samaa mieltä"
                          value="5"
                        ></v-radio>
                      </v-radio-group>
                      <v-divider class="mx-4"></v-divider>
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-row align="center" justify="end">
                    <!-- <v-icon class="mr-1">
                  mdi-heart
                </v-icon>
                <span class="subheading mr-2">256</span> -->

                    <span class="subheading"></span>
                  </v-row>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <template>
        <v-flex text-center xs12 md12 class="my-5">
          <v-btn color="grey lighten-2" dense @click="showDialog">
            Lähetä kysely
          </v-btn>
        </v-flex>
      </template>
    </v-row>
    <v-dialog v-model="createDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          Kiitos kyselyyn osallistumisesta!
        </v-card-title>
        <v-card-text>
          <p>Olet nyt syöttänyt kaikki vastaukset.</p>
          <p>
            Mikäli haluat muuttaa vielä vastauksiasi niin paina Muokkaa vast
          </p>

          <p>
            Ole hyvä ja lähetä vstausket painamlla Lähetetä vastaukset.
          </p>
          <p>Tämän jälkeen et voi enään muokata vastauksiasi</p>
          .
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeModal"
            >Muokkaa vastauksiasi</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveSurvey"
            >Lähetä vastaukset</v-btn
          >

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import QueryService from '@/services/PowerGuard/QueryService.js';
import QuerySResultService from '@/services/PowerGuard/QueryResultService.js';

export default {
  data() {
    return {
      query: [],
      questions: [],
      message: null,
      error: null,
      answers: {},
      results: {},
      queryTitle: '',
      queryDescription: '',
      createDialog: false,
    };
  },
  async created() {
    try {
      const response = await QueryService.getQueryByCustomerId();
      this.questions = response.data.questions;
      console.log(this.questions);
      this.query = response.data.query;
      this.queryTitle = this.query[0].queryTitle;
      this.queryDescription = this.query[0].queryDescription;
      this.results.queryId = this.query[0].queryId;
      this.results.queryUserId = this.$store.state.user.uuid;
    } catch (error) {
      this.error = error;
    }
  },

  methods: {
    showDialog() {
      this.createDialog = true;
    },
    closeModal() {
      this.createDialog = false;
    },
    // saveSurvey() {
    //   console.log(this.answers);
    //   this.createDialog = false;
    // },

    async saveSurvey() {
      console.log(this.answers);
      this.results.queryResults = this.answers;
      // let ans = this.answers.join();
      // this.results.queryResults = ans;
      this.createDialog = false;
      this.$router.push({
        name: 'powerguard',
      });

      try {
        const response = await QuerySResultService.addNewQueryResult(
          this.results
        );
        this.results = {};
        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
<style scoped></style>
