<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card :elevation="hover ? 24 : 6" class="mb-4 pa-2">
              <v-card-title class="headline">
                {{ discussion[0].discussionTitle }}
              </v-card-title>

              <v-card-subtitle>{{
                discussion[0].discussionDescription
              }}</v-card-subtitle>

              <v-card-actions>
                <v-btn @click="addNewDiscussionComment()">
                  Lisää kommentti
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" v-for="(comment, index) in comments" :key="index">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card :elevation="hover ? 24 : 6" class="mb-2 pa-2">
              <v-card-title>
                <v-avatar
                  :color="showColor(comment.User.userFirstName)"
                  size="40"
                >
                  <span class="white--text ">{{
                    showNameLetters(
                      comment.User.userFirstName,
                      comment.User.userLastName
                    )
                  }}</span>
                </v-avatar>
                <span class="title pl-2">{{
                  showDateAndTime(comment.createdAt)
                }}</span>
              </v-card-title>

              <v-card-text
                ><h3>
                  {{ comment.discussionComment }}
                </h3></v-card-text
              >

              <v-card-actions>
                <v-list-item class="grow">
                  <v-list-item-content>
                    <v-list-item-title
                      >{{
                        showFirtsNameLastName(
                          comment.User.userFirstName,
                          comment.User.userLastName
                        )
                      }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-row align="center" justify="end">
                    <!-- <v-icon class="mr-1">
                  mdi-heart
                </v-icon>
                <span class="subheading mr-2">256</span> -->

                    <a
                      v-if="showSubCommentIcon(comment.DiscussionSubComment)"
                      class="mr-1"
                      @click="showSubComments(comment.discussionCommentId)"
                    >
                      Katso kommentit
                    </a>

                    <a
                      v-else
                      class="mr-1"
                      @click="
                        addNewDiscussionSubComment(comment.discussionCommentId)
                      "
                    >
                      Lisää kommentti
                    </a>

                    <span class="subheading"></span>
                  </v-row>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DiscussionService from '@/services/PowerGuard/DiscussionService';
import DiscussionCommentService from '@/services/PowerGuard/DiscussionCommentService';
import moment from 'moment';

export default {
  data() {
    return {
      summary: true,
      comments: [
        {
          discussionId: '',
          discussionCommentId: '',
          discussionCommentSubId: '',
          discussionComment: '',
          createdAt: '',
          User: [
            {
              userLastName: '',
              userFirstName: '',
            },
          ],
        },
      ],

      discussion: [
        {
          id: '',
          discussionDescription: '',
          discussionId: '',
          discussionTitle: '',
          createdAt: '',
        },
      ],
      item: '',
    };
  },

  async mounted() {
    try {
      const discussion = await DiscussionService.getDiscussionByDiscussionId({
        discussionId: this.$store.getters.getDiscussionId,
      });

      this.discussion = discussion.data.discussion;
    } catch (error) {
      this.error = error;
    }
    try {
      const discussionComments = await DiscussionCommentService.getDiscussionCommentsByDiscussionId(
        {
          discussionId: this.$store.getters.getDiscussionId,
        }
      );
      this.comments = discussionComments.data.comments;
      console.log(this.comments);
    } catch (error) {
      this.error = error;
    }
  },
  filters: {
    limitToDisplay: function(value) {
      if (!value) return '';
      return value.substring(0, 50) + '...';
    },
  },
  methods: {
    showColor(name) {
      if (name.startsWith('T')) {
        return 'red';
      }
      return 'green';
    },
    showSubCommentIcon(count) {
      if (count) return true;
      return false;
    },
    showDateAndTime(dateTime) {
      return moment(dateTime).format('DD.MM.YYYY');
    },
    showNameLetters(firstName, lastName) {
      if (firstName && lastName) {
        return firstName.substring(0, 1) + lastName.substring(0, 1);
      }
    },
    showFirtsNameLastName(firstName, lastName) {
      return firstName + ' ' + lastName;
    },
    showSubComments(id) {
      this.$store.dispatch('setDiscussionCommentId', id);
      this.$router.push({
        name: 'powerguard/sub-comments',
      });
    },

    addNewDiscussionComment() {
      this.$router.push({
        name: 'powerguard/add-new-comment',
        //params: { discussionId: this.discussion.discussionId },
      });
    },
    addNewDiscussionSubComment(id) {
      this.$store.dispatch('setDiscussionCommentId', id);
      this.$router.push({
        name: 'powerguard/add-new-sub-comment',
        // params: { discussionId: this.discussion.discussionId },
      });
    },
  },
};
</script>

<style></style>
