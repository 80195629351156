var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c('v-app-bar',{attrs:{"color":"primary","dark":"","app":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","dark":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")])],1)]}}])},[_c('v-list',_vm._l((_vm.users),function(ref,i){
var title = ref[0];
var icon = ref[1];
var route = ref[2];
return _c('v-list-item',{key:i,attrs:{"to":route,"active-class":"border","link":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-title',[_vm._v(_vm._s(title))])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","dark":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-tools")])],1)]}}])},[_c('v-list',_vm._l((_vm.tools),function(ref,i){
var title = ref[0];
var icon = ref[1];
var route = ref[2];
return _c('v-list-item',{key:i,attrs:{"to":route,"active-class":"border","link":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-title',[_vm._v(_vm._s(title))])],1)}),1)],1),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.logout}},[_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-export")])],1)],1),_c('v-navigation-drawer',{staticClass:"primary darken-2",attrs:{"dark":"","app":"","width":"300"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{staticClass:"mt-5",attrs:{"align-left":""}},[_c('v-avatar',[_c('v-img',{attrs:{"src":"https://randomuser.me/api/portraits/thumb/men/75.jpg"}})],1),_c('p',{staticClass:"white--text subheading mt-1 text-center"},[_vm._v(" "+_vm._s(_vm.user.userEmail)+" ")])],1),_c('v-flex',{staticClass:"mt-4 mb-4"},[_c('v-divider',{attrs:{"color":"white"}})],1)],1),_c('v-list',{staticClass:"text-left"},[_c('v-list-item-group',[_c('v-list-item',{attrs:{"to":"/dashboard"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('main.dashboard')))])],1)],1)],1),_c('v-list-group',{attrs:{"value":false,"prepend-icon":"mdi-account-circle"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Käyttäjät")])]},proxy:true}])},_vm._l((_vm.userLinks),function(ref,i){
var title = ref[0];
var icon = ref[1];
var route = ref[2];
return _c('v-list-item',{key:i,attrs:{"link":"","to":route,"sub-group":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1)],1)}),1),_c('v-list-group',{attrs:{"value":false,"prepend-icon":"mdi-office-building"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Kohteet")])]},proxy:true}])},_vm._l((_vm.targets),function(ref,i){
var title = ref[0];
var icon = ref[1];
var route = ref[2];
return _c('v-list-item',{key:i,attrs:{"link":"","to":route,"sub-group":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1)],1)}),1),_c('v-list-group',{attrs:{"value":false,"prepend-icon":"mdi-home-city"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Organisaatiot")])]},proxy:true}])},_vm._l((_vm.organizations),function(ref,i){
var title = ref[0];
var icon = ref[1];
var route = ref[2];
return _c('v-list-item',{key:i,attrs:{"link":"","to":route,"sub-group":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1)],1)}),1),_c('v-list-group',{attrs:{"value":false,"prepend-icon":"mdi-leak"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Anturit/laitteet")])]},proxy:true}])},_vm._l((_vm.sensors),function(ref,i){
var title = ref[0];
var icon = ref[1];
var route = ref[2];
return _c('v-list-item',{key:i,attrs:{"link":"","to":route,"sub-group":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1)],1)}),1),_c('v-list-group',{staticClass:"red",attrs:{"value":false,"prepend-icon":"mdi-alarm-light"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Hälytykset")])]},proxy:true}])},_vm._l((_vm.alarms),function(ref,i){
var title = ref[0];
var icon = ref[1];
var route = ref[2];
return _c('v-list-item',{key:i,staticClass:"blue",attrs:{"link":"","to":route,"sub-group":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }