import Api from '@/services/Api';
import store from '@/store';
//User api cals
export default {
  getDiscussionByDiscussionId(params) {
    console.log(params);
    return Api().get('/powerguard/discussion/id', {
      params,
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  getAllDiscussions() {
    return Api().get('/powerguard/discussions/', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  saveDiscussionModification(credintials) {
    return Api().patch('/powerguard/discussion', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  addNewDiscussion(credintials) {
    return Api().post('/powerguard/discussion/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  getQueryByCustomerId(credintials) {
    return Api().post('/powerguard/query-customer-id/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};
