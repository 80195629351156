import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Main from '../views/Main.vue';
import PowerGuard from '@/views/PowerGuard.vue';
import IndoorAir from '@/views/IndoorAir.vue';
import Store from '@/store';
import NotFound from '@/components/Helpers/NotFound';
import AddUser from '@/components/Main/Users/Register';
import ShowUsers from '@/components/Main/Users/ShowUsers';
import Dashboard from '@/components/Dashboard/Dashboard';
import AddTarget from '@/components/IndoorAir/Targets/AddTarget';
import ShowTargets from '@/components/IndoorAir/Targets/ShowTargets';
import AddOrganization from '@/components/Main/Organizations/AddOrganization';
import ShowOrganizations from '@/components/Main/Organizations/ShowOrganizations';
import AddSensor from '@/components/IndoorAir/Sensors/AddSensor';
import ShowSensors from '@/components/IndoorAir/Sensors/ShowSensors';
import ChangePassword from '@/components/Main/Users/ChangePassword';
import EditUserInformation from '@/components/Main/Users/EditUserInformation';
//Power Guaard
import AddNewPowerGuardQuery from '@/components/PowerGuard/PowerGuardQuery/AddNewPowerGuardQuery';
import ShowPowerGuardQueries from '@/components/PowerGuard/PowerGuardQuery/ShowPowerGuardQueries';
import AddNewPowerGuardQuestion from '@/components/PowerGuard/PowerGuardQuestions/AddNewPowerGuardQuestion';
import AddNewPowerGuardQuestionGroup from '@/components/PowerGuard/PowerGuardQuestionGroups/AddNewPowerGuardQuestionGroup';
import ShowPowerGuardQuestions from '@/components/PowerGuard/PowerGuardQuestions/ShowPowerGuardQuestions';
import ShowPowerGuardQuestionGroups from '@/components/PowerGuard/PowerGuardQuestionGroups/ShowPowerGuardQuestionGroups';
import ShowPowerGuardQueryResults from '@/components/PowerGuard/PowerGuardQueryResults/ShowPowerGuardQueryResults';
import ShowPowerGuardSurveyForm from '@/components/PowerGuard/PowerGuardSurvey/ShowPowerGuardSurveyForm';
import ShowPowerGuardSurveyResults from '@/components/PowerGuard/PowerGuardSurvey/ShowPowerGuardSurveyResults';
import addNewFeedback from '@/components/PowerGuard/Feedback/AddFeedback';
import ShowFeedbacks from '@/components/PowerGuard/Feedback/ShowFeedbacks';
import AddMaterialBank from '@/components/PowerGuard/MaterialBank/AddFile';
import ShowMaterialBankFiles from '@/components/PowerGuard/MaterialBank/ShowMaterialBankFiles';
import Discussions from '@/components/PowerGuard/Discussions/Discussions';
import ShowCurrentDiscussion from '@/components/PowerGuard/Discussions/ShowCurrentDiscussion';
import AddNewDiscussion from '@/components/PowerGuard/Discussions/AddNewDiscussion';
import ShowDiscussions from '@/components/PowerGuard/Discussions/ShowDiscussions';
import AddNewComment from '@/components/PowerGuard/Discussions/AddNewComment';
import ShowDiscussionSubComments from '@/components/PowerGuard/Discussions/ShowDiscussionSubComments';
import AddNewSubComment from '@/components/PowerGuard/Discussions/AddNewSubComment';
Vue.use(VueRouter);
//Client side routes
const routes = [
  {
    path: '*',
    component: NotFound,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/main',
    name: 'main',
    component: Main,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: '/discussions',
  //   name: 'discussions',
  //   component: Discussions,
  // },

  //Power guard routes
  {
    path: '/powerguard',
    name: 'powerguard',
    component: PowerGuard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'add-new-query',
        components: {
          powerguardview: AddNewPowerGuardQuery,
        },
      },
      {
        path: 'show-queries',
        components: {
          powerguardview: ShowPowerGuardQueries,
        },
      },
      {
        path: 'add-new-question',
        components: {
          powerguardview: AddNewPowerGuardQuestion,
        },
      },
      {
        path: 'show-questions',
        components: {
          powerguardview: ShowPowerGuardQuestions,
        },
      },
      {
        path: 'add-new-question-group',
        components: {
          powerguardview: AddNewPowerGuardQuestionGroup,
        },
      },
      {
        path: 'show-question-groups',
        components: {
          powerguardview: ShowPowerGuardQuestionGroups,
        },
      },
      {
        path: 'show-query-results',
        components: {
          powerguardview: ShowPowerGuardQueryResults,
        },
      },
      {
        path: 'show-survey-form',
        components: {
          powerguardview: ShowPowerGuardSurveyForm,
        },
      },
      {
        path: 'show-survey-results',
        name: 'show-survey-results',
        components: {
          powerguardview: ShowPowerGuardSurveyResults,
        },
      },
      {
        path: 'add-feedback',
        components: {
          powerguardview: addNewFeedback,
        },
      },
      {
        path: 'show-feedbacks',
        name: 'show-feedbacks',
        components: {
          powerguardview: ShowFeedbacks,
        },
      },
      {
        path: 'add-materialbank-file',
        components: {
          powerguardview: AddMaterialBank,
        },
      },
      {
        path: 'show-materialbank-files',
        components: {
          powerguardview: ShowMaterialBankFiles,
        },
      },

      {
        path: 'show-discussions',
        components: {
          powerguardview: ShowDiscussions,
        },
      },
      {
        path: 'discussions',
        components: {
          powerguardview: Discussions,
        },
      },
      {
        path: 'add-discussion',
        components: {
          powerguardview: AddNewDiscussion,
        },
      },
      {
        path: 'current-discussions',
        name: 'powerguard/discussions',
        components: {
          powerguardview: ShowCurrentDiscussion,
        },
      },
      {
        path: 'add-new-comment',
        name: 'powerguard/add-new-comment',
        components: {
          powerguardview: AddNewComment,
        },
      },
      {
        path: 'show-sub-comments',
        name: 'powerguard/sub-comments',
        components: {
          powerguardview: ShowDiscussionSubComments,
        },
      },
      {
        path: 'add-new-sub-comment',
        name: 'powerguard/add-new-sub-comment',
        components: {
          powerguardview: AddNewSubComment,
        },
      },
    ],
  },

  //Powerguard routes end

  //Indoor air routes

  {
    path: '/indoorair',
    name: 'indoorair',
    component: IndoorAir,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'show-users',
        components: {
          indoorairview: ShowUsers,
        },
      },
      {
        path: 'add-user',
        components: {
          indoorairview: AddUser,
        },
      },
      {
        path: 'add-target',
        components: {
          indoorairview: AddTarget,
        },
      },
      {
        path: 'show-targets',
        components: {
          indoorairview: ShowTargets,
        },
      },
      {
        path: 'add-organization',
        components: {
          indoorairview: AddOrganization,
        },
      },
      {
        path: 'show-organizations',
        components: {
          indoorairview: ShowOrganizations,
        },
      },
      {
        path: 'add-sensor',
        components: {
          indoorairview: AddSensor,
        },
      },
      {
        path: 'show-sensors',
        components: {
          indoorairview: ShowSensors,
        },
      },

      {
        path: 'dashboard',
        components: {
          dashboard: Dashboard,
        },
      },
      {
        path: 'change-password',
        components: {
          indoorairview: ChangePassword,
        },
      },
      {
        path: 'edit-user-information',
        components: {
          indoorairview: EditUserInformation,
        },
      },
    ],
  },

  //Indoor air routes end

  {
    path: '/',
    name: 'main1',
    component: Main,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'dashboard',
        components: {
          dashboard: Dashboard,
        },
      },
      {
        path: 'change-password',
        components: {
          dashboard: ChangePassword,
        },
      },
      {
        path: 'edit-user-information',
        components: {
          dashboard: EditUserInformation,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!Store.state.isAuthenticated) {
      next({
        path: '/login',
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
