import Api from '@/services/Api';
import store from '@/store';
//User api cals
export default {
  getAllQueryResult() {
    return Api().get('/powerguard/queries/', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  addNewQueryResult(credintials) {
    return Api().post('/powerguard/result/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  getQueryResultsByQueryId(credintials) {
    return Api().post('/powerguard/results/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};
