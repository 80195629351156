import Api from '@/services/Api';
import store from '@/store';
//User api cals
export default {
  getAllQueries() {
    return Api().get('/powerguard/queries/', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  saveQueryModification(credintials) {
    return Api().patch('/powerguard/query', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  addNewQuery(credintials) {
    return Api().post('/powerguard/query/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  getQueryByCustomerId(credintials) {
    return Api().get('/powerguard/query-customer-id/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};
