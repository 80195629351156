<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left><h4>Lisää uusi kommentti</h4></v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  label="Kommentti"
                  prepend-icon="mdi-information-variant"
                  v-model="addNewSubComment.discussionSubComment"
                  autocomplete="off"
                  required
                />
              </v-flex>

              <v-flex xs6 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click="addSubComment"
                >
                  Lisää kommentti
                </v-btn>
              </v-flex>
              <v-flex xs6 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light float-right"
                  color="general"
                  @click="backToComments"
                >
                  Takaisin
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import DiscussionCommentSubService from '@/services/PowerGuard/DiscussionCommentSubService.js';
export default {
  data() {
    return {
      addNewSubComment: {},
      message: null,
      error: null,
      rules: {
        required: (value) => !!value || 'Pakollinen tieto',
      },
    };
  },
  created() {
    this.addNewSubComment.discussionId = this.$store.getters.getDiscussionId;
    this.addNewSubComment.discussionSubCommentUserId = this.$store.state.user.uuid;
    this.addNewSubComment.discussionCommentId = this.$store.getters.getDiscussionCommentId;
  },
  methods: {
    async addSubComment() {
      try {
        const response = await DiscussionCommentSubService.addNewSubComment(
          this.addNewSubComment
        );
        this.addNewSubComment = {};
        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
        this.$router.push({
          name: 'powerguard/sub-comments',
        });
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    backToComments() {
      this.$router.push({
        name: 'powerguard/sub-comments',
      });
    },
  },
};
</script>
