<template>
  <v-data-table
    :headers="headers"
    :items="files"
    sort-by="queries"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Materiaalipankki</v-toolbar-title>

        <v-spacer></v-spacer>
        <!-- <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      label="Kysymys"
                      prepend-icon="mdi-information-variant"
                      v-model="questionItems.questionDescription"
                      autocomplete="off"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      prepend-icon="mdi-account-supervisor"
                      v-model="questionItems.questionState"
                      :items="questionStates"
                      label="Kyselyn tila"
                      placeholder="Valitse"
                      item-text="name"
                      item-value="value"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
      </v-toolbar>
    </template>

    <template v-slot:[`item.materialBankFile`]="{ item }">
      <a href="#" @click.prevent="readFile(item.materialBankFile)" class="link">
        {{ item.materialBankFile }}</a
      >
    </template>
  </v-data-table>
</template>
<script>
import MaterialbankService from '@/services/PowerGuard/MaterialbankService.js';

export default {
  data() {
    return {
      files: [],
      dialog: false,
      error: null,
      message: null,
      headers: [
        { text: 'Otsikko', value: 'materialBankTitle' },
        { text: 'Tiedosto', value: 'materialBankFile' },
      ],
      questionStates: [
        { name: 'Aktiivinen', value: 'active' },
        {
          name: 'Passiivinen',
          value: 'passive',
        },
      ],
    };
  },
  methods: {
    readFile(file) {
      window.open('http://localhost:3000/uploads/' + file, '_blank');
    },
  },

  async created() {
    const response = await MaterialbankService.getAllMaterialBankFiles();
    this.files = response.data.files;
  },
};
</script>
<style scoped>
.link {
  text-decoration: none;
}
</style>
