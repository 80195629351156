<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left><h4>Lisää ryhmä</h4></v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  label="Rymhä"
                  prepend-icon="mdi-information-variant"
                  v-model="addQuestionGroupValues.queryQuestionGroupName"
                  autocomplete="off"
                  required
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  label="Kuvaus ryhmästä"
                  prepend-icon="mdi-information-variant"
                  v-model="addQuestionGroupValues.queryQuestionGroupDescrption"
                  autocomplete="off"
                  required
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addQuestionGroupValues.queryQuestionGroupState"
                  :items="questionGroupState"
                  item-text="name"
                  item-value="value"
                  label="Ryhmän tila"
                  placeholder="Valitse"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewQuestionGroup"
                >
                  Lisää uusi kysely
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import QuestionGroupService from '@/services/PowerGuard/QuestionGroupService.js';
export default {
  data() {
    return {
      questionGroupState: [
        { name: 'Käytössä', value: 'Käytössä' },
        { name: 'Poissa käytöstä', value: 'Poissa käytöstä' },
      ],
      addQuestionGroupValues: {},
      message: null,
      error: null,
      rules: {
        required: (value) => !!value || 'Pakollinen tieto',
      },
    };
  },

  methods: {
    async addNewQuestionGroup() {
      try {
        console.log(this.addQuestionGroupValues);
        const response = await QuestionGroupService.addNewQuestionGroup(
          this.addQuestionGroupValues
        );
        this.addQuestionGroupValues = {};
        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
