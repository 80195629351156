<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  mounted() {
    this.renderChart(
      {
        labels: ['Tammikuu', 'Helmikuu', 'Maaliskuu', 'Toukokuu', 'Kesäkuu'],
        datasets: [
          {
            label: 'Data 1',
            backgroundColor: 'blue',
            data: [40, 20, 12, 39, 10],
          },
          {
            label: 'Data 2',
            backgroundColor: 'green',
            data: [15, 7, 12, 11, 14],
          },
          {
            label: 'Data 3',
            backgroundColor: 'red',
            data: [25, 11, 12, 17, 10],
          },
        ],
      },
      { responsive: true, maintainAspectRatio: false }
    );
  },
};
</script>
