<!-- Top and sidebar template -->
<template>
  <nav>
    <v-app-bar color="#424242" app dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <v-tab @click="showMessages">
        <v-badge color="red" :content="getMessages">
          Viestit
        </v-badge>
      </v-tab>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text dark v-on="on">
            <v-icon left>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="([title, icon, route], i) in users"
            :key="i"
            :to="route"
            active-class="border"
            link
          >
            <v-list-item-icon><v-icon v-text="icon"></v-icon></v-list-item-icon>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn text @click="logout">
        <v-icon right>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <!--NAvigointi drawer alkaa -->
    <!-- expand-on-hover -->
    <v-navigation-drawer v-model="drawer" app dark color="#424242" width="300">
      <v-layout column>
        <v-flex class="mt-5">
          <v-avatar>
            <v-img
              src="https://randomuser.me/api/portraits/thumb/men/75.jpg"
            ></v-img>
          </v-avatar>
          <p class="white--text subheading mt-1 text-center">
            {{ user.userEmail }}
          </p>
        </v-flex>
        <v-flex class="mt-4 mb-4">
          <v-divider color="white"></v-divider>
        </v-flex>
      </v-layout>
      <!-- Drop-down -->
      <v-list class="text-left">
        <v-list-item to="/dashboard">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/powerguard/discussions">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Keskustelut</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/powerguard/show-survey-form">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Tee Kysely</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/powerguard/add-feedback">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Palaute</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!--Home link end -->
        <!--User group -->

        <v-list-group :value="false" prepend-icon="mdi-account-circle">
          <template v-slot:activator>
            <v-list-item-title>Kysymykset</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in questionLinks"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!--User group end -->
        <!--Targets group -->

        <v-list-group :value="false" prepend-icon="mdi-office-building">
          <template v-slot:activator>
            <v-list-item-title>Kyselyt</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in queries"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!--queries group end -->

        <v-list-group :value="false" prepend-icon="mdi-office-building">
          <template v-slot:activator>
            <v-list-item-title>Materiaalipankki</v-list-item-title>
          </template>
          <v-list-item
            v-for="([title, icon, route], i) in materialBank"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="false" prepend-icon="mdi-office-building">
          <template v-slot:activator>
            <v-list-item-title>Keskustelut</v-list-item-title>
          </template>
          <v-list-item
            v-for="([title, icon, route], i) in discussions"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      <!-- Drop-down end -->
    </v-navigation-drawer>
    <!--NAvigointi drawer alkaa -->
  </nav>
</template>

<script>
import FeedbackService from '@/services/PowerGuard/FeedbackService';
export default {
  data: () => ({
    name: 'navbar',
    user: [],
    drawer: true,
    feedbacks: [],

    users: [
      ['Muokkaa tietoja', 'mdi-account-edit', '/edit-user-information'],
      ['Vaihada salasana', 'mdi-lock', '/change-password'],
    ],
    queries: [
      ['Lisää uusi kysely', '', '/powerguard/add-new-query'],
      ['Näytä kyselyt', '', '/powerguard/show-queries'],
    ],

    questionLinks: [
      ['Lisää uusi kysymys ryhmä', '', '/powerguard/add-new-question-group'],
      ['Näytä kysymys ryhmät', '', '/powerguard/show-question-groups'],
      ['Lisää uusi kysymys', '', '/powerguard/add-new-question'],
      ['Näytä kysymykset', '', '/powerguard/show-questions'],
    ],
    materialBank: [
      ['Lisää uusi tiedosto', '', '/powerguard/add-materialbank-file'],
      ['Näytä tiedostot', '', '/powerguard/show-materialbank-files'],
    ],
    discussions: [
      ['Lisää uusi uusi keskustelu', '', '/powerguard/add-discussion'],
      ['Näytä keskustelut', '', '/powerguard/show-discussions'],
    ],
  }),
  computed: {
    getMessages() {
      let message = this.feedbacks.length;
      return message;
    },
  },

  //When page is loaded
  async created() {
    this.user = this.$store.state.user;
    try {
      const response = await FeedbackService.getAllFeedbacks();
      this.feedbacks = response.data.feedbacks;
    } catch (error) {
      this.error = error;
    }
  },

  methods: {
    showMessages() {
      this.$router.push({
        name: 'show-feedbacks',
      });
    },
    /**
     * User click logout button
     * Set token null
     * Set user null
     * */
    logout() {
      this.$store.dispatch('setToken', null);
      this.$store.dispatch('setUser', null);
      this.$router.push({
        name: 'login',
      });
    },
  },
};
</script>

<style scoped>
.v-list .v-list-item--active,
.v-list .v-list-item--active .v-icon {
  color: white;
}
</style>
