<!-- Add new organization template -->
<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12
                ><h2>{{ $t('organizations.organizationTitle') }}</h2></v-flex
              >
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('organizations.organizationBusinessId')"
                  prepend-icon="mdi-domain"
                  :rules="[rules.required]"
                  v-model="organizationValues.organizationBusinessId"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('organizations.organizationContractNumber')"
                  prepend-icon="mdi-file-document-edit-outline"
                  :rules="[rules.required]"
                  v-model="organizationValues.organizationContractNumber"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('organizations.organizationName')"
                  prepend-icon="mdi-home-city-outline"
                  :rules="[rules.required]"
                  v-model="organizationValues.organizationName"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('organizations.organizationAddress')"
                  prepend-icon="mdi-map-marker"
                  :rules="[rules.required]"
                  v-model="organizationValues.organizationAddress"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('organizations.organizationZipCode')"
                  prepend-icon="mdi-alpha-z-box-outline"
                  :rules="[rules.required]"
                  v-model="organizationValues.organizationZipCode"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('organizations.organizationPostalDistrict')"
                  prepend-icon="mdi-home-map-marker"
                  :rules="[rules.required]"
                  v-model="organizationValues.organizationPostalDistrict"
                  type="password"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('organizations.organizationArea')"
                  prepend-icon="mdi-flag"
                  :rules="[rules.required]"
                  v-model="organizationValues.organizationArea"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="organizationValues.organizationState"
                  :items="organizationState"
                  item-text="name"
                  item-value="value"
                  :label="$t('organizations.organizationState')"
                  placeholder="Valitse..."
                  required
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 md12>
                <v-textarea
                  :label="$t('organizations.organizationInformation')"
                  prepend-icon="mdi-information-variant"
                  v-model="organizationValues.organizationInformation"
                  autocomplete="off"
                  :counter="500"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  :label="$t('organizations.organizationDescription')"
                  prepend-icon="mdi-comment-text-outline"
                  v-model="organizationValues.organizationDescription"
                  autocomplete="off"
                  :counter="500"
                />
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewOrganization"
                >
                  {{ $t('organizations.organizationRegisterButton') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import OrganizationService from '@/services/OrganizationService.js';
export default {
  data() {
    return {
      organizationState: [
        { name: this.$t('register.state-active'), value: 'active' },
        { name: this.$t('register.state-passive'), value: 'passive' },
      ],

      organizationValues: {},
      message: null,
      error: null,
      rules: {
        required: (value) => !!value || this.$t('register.required'),
      },
    };
  },
  methods: {
    /**
     * Add new button is clicked
     */
    async addNewOrganization() {
      try {
        console.log(this.organizationValues);
        const response = await OrganizationService.addNewOrganization(
          this.organizationValues
        );

        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
