import Api from '@/services/Api';
import store from '@/store';
//Organization api cals
export default {
  getAllOrganizations() {
    return Api().get('/organizations', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  saveOrganizationModification(credintials) {
    return Api().patch('/organization/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  addNewOrganization(credintials) {
    return Api().post('/organization', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};
