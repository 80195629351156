<!-- Top and sidebar template -->
<template>
  <nav>
    <v-app-bar color="primary" dark app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text dark v-on="on">
            <v-icon left>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="([title, icon, route], i) in users"
            :key="i"
            :to="route"
            active-class="border"
            link
          >
            <v-list-item-icon><v-icon v-text="icon"></v-icon></v-list-item-icon>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text dark v-on="on">
            <v-icon left>mdi-tools</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="([title, icon, route], i) in tools"
            :key="i"
            :to="route"
            active-class="border"
            link
          >
            <v-list-item-icon><v-icon v-text="icon"></v-icon></v-list-item-icon>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn text @click="logout">
        <v-icon right>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <!--NAvigointi drawer alkaa -->
    <!-- expand-on-hover -->
    <v-navigation-drawer
      v-model="drawer"
      dark
      app
      class="primary darken-2"
      width="300"
    >
      <v-layout column>
        <v-flex class="mt-5" align-left>
          <v-avatar>
            <v-img
              src="https://randomuser.me/api/portraits/thumb/men/75.jpg"
            ></v-img>
          </v-avatar>
          <p class="white--text subheading mt-1 text-center">
            {{ user.userEmail }}
          </p>
        </v-flex>
        <v-flex class="mt-4 mb-4">
          <v-divider color="white"></v-divider>
        </v-flex>
      </v-layout>
      <!-- Drop-down -->
      <v-list class="text-left">
        <!--Home link-->
        <v-list-item-group>
          <v-list-item to="/dashboard">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('main.dashboard') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <!--Home link end -->
        <!--User group -->

        <v-list-group :value="false" prepend-icon="mdi-account-circle">
          <template v-slot:activator>
            <v-list-item-title>Käyttäjät</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in userLinks"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!--User group end -->
        <!--Targets group -->

        <v-list-group :value="false" prepend-icon="mdi-office-building">
          <template v-slot:activator>
            <v-list-item-title>Kohteet</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in targets"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!--Targets group end -->

        <!--Targets group -->

        <v-list-group :value="false" prepend-icon="mdi-home-city">
          <template v-slot:activator>
            <v-list-item-title>Organisaatiot</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in organizations"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!--Targets group end -->
        <!--Alarms group -->

        <v-list-group :value="false" prepend-icon="mdi-leak">
          <template v-slot:activator>
            <v-list-item-title>Anturit/laitteet</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in sensors"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!--Alarms group end -->
        <!--Alarms group -->

        <v-list-group :value="false" prepend-icon="mdi-alarm-light" class="red">
          <template v-slot:activator>
            <v-list-item-title>Hälytykset</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in alarms"
            :key="i"
            link
            :to="route"
            sub-group
            class="blue"
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!--Alarms group end -->
      </v-list>
      <!-- Drop-down end -->
    </v-navigation-drawer>
    <!--NAvigointi drawer alkaa -->
  </nav>
</template>

<script>
//import Popup from './Popup.vue';
export default {
  data: () => ({
    name: 'navbar',
    user: [],
    drawer: true,

    users: [
      ['Muokkaa tietoja', 'mdi-account-edit', '/edit-user-information'],
      ['Vaihada salasana', 'mdi-lock', '/change-password'],
    ],
    tools: [
      ['Lisää mittariluokka', 'mdi-thermometer-lines', '/edit-user-information'],
      ['Lisää mittariluokan enkoodaus', 'mdi-hexadecimal', '/change-password'],
    ],
    targets: [
      ['Lisää uusi kohde', 'mdi-home-city', '/indoorair/add-target'],
      ['Näytä kohteet', 'mdi-home-group', '/indoorair/show-targets'],
    ],
    alarms: [['Näytä hälytykset', 'mdi-alarm-ligth', '/indoorair/show-alarms']],

    userLinks: [
      ['Lisää uusi käyttäjä', '', '/indoorair/add-user'],
      ['Näytä käyttäjät', '', '/indoorair/show-users'],
    ],
    organizations: [
      ['Lisää uusi organisaatio', '', '/indoorair/add-organization'],
      ['Näytä organisaatiot', '', '/indoorair/show-organizations'],
    ],
    sensors: [
      ['Lisää uusi anturi', '', '/indoorair/add-sensor'],
      ['Näytä antirit', '', '/indoorair/show-sensors'],
    ],
  }),
  //When page is loaded
  created() {
    this.user = this.$store.state.user;
  },
  methods: {
    /**
     * User click logout button
     * Set token null
     * Set user null
     * */
    logout() {
      this.$store.dispatch('setToken', null);
      this.$store.dispatch('setUser', null);
      this.$router.push({
        name: 'login',
      });
    },
  },
};
</script>

<style scoped></style>
