<!-- Main view when user is logged in -->
<template>
  <v-app>
    <IndoorAirNarBar />

    <v-main class="ma-4">
      <router-view name="indoorairview"></router-view>
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
import IndoorAirNarBar from '@/components/IndoorAir/Menu/IndoorAirNavBar';
import Footer from '@/components/IndoorAir/Menu/Footer';

export default {
  data() {
    return {
      username: '',
    };
  },
  components: {
    IndoorAirNarBar,
    Footer,
  },
  async created() {
    // if (!this.$store.getters.isLoggedIn) {
    //  this.$router.push('/login');
    // }
    this.username = this.$store.getters.getUser.username;
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
};
</script>
