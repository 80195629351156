<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center>
      <v-flex xs12 md8 row>
        <v-flex
          xs12
          md4
          v-for="(discussion, index) in discussions"
          :key="index"
        >
          <v-card
            class="mx-auto"
            min-height="200"
            v-if="discussion.discussionState == 'Avoin'"
          >
            <v-card-text>
              <div>{{ discussion.discussionTitle }}</div>

              <div class="text--primary">
                {{ discussion.discussionDescription }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="teal accent-4"
                @click="showDiscussion(discussion.discussionId)"
              >
                Liity keskusteluun
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex xs12 md4> </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import DiscussionService from '@/services/PowerGuard/DiscussionService.js';
export default {
  data() {
    return {
      discussions: {
        discussionId: '',
      },
    };
  },
  methods: {
    showDiscussion(discussionId) {
      this.$store.dispatch('setDiscussionId', discussionId);
      this.$router.push({
        name: 'powerguard/discussions',
      });
    },
  },

  async created() {
    const response = await DiscussionService.getAllDiscussions();
    this.discussions = response.data.discussions;
  },
};
</script>
