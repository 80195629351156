<template>
  <v-app>
    <main>
      <router-view />
    </main>
  </v-app>
</template>
<script>
export default {
  name: 'App',
};
</script>
<style></style>
