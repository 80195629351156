import Api from '@/services/Api';
import store from '@/store';
//User api cals
export default {
  getAllMaterialBankFiles() {
    return Api().get('/powerguard/materialbank/', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  saveFile(credintials) {
    console.log(credintials);
    return Api().post(
      '/powerguard/materialbank/',
      credintials,
      {
        onUploadProgress: (uploadEvent) => {
          console.log(
            'Upload progress: ' +
              Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
              '%'
          );
        },
      },
      {
        headers: {
          Authorization: 'Bearer ' + store.getters.getToken,
        },
      }
    );
  },
  //   getQueryResultsByQueryId(credintials) {
  //     return Api().post('/powerguard/results/', credintials, {
  //       headers: {
  //         Authorization: 'Bearer ' + store.getters.getToken,
  //       },
  //     });
  //   },
};
