var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c('v-app-bar',{attrs:{"color":"#424242","app":"","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-spacer'),_c('v-tab',{on:{"click":_vm.showMessages}},[_c('v-badge',{attrs:{"color":"red","content":_vm.getMessages}},[_vm._v(" Viestit ")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","dark":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")])],1)]}}])},[_c('v-list',_vm._l((_vm.users),function(ref,i){
var title = ref[0];
var icon = ref[1];
var route = ref[2];
return _c('v-list-item',{key:i,attrs:{"to":route,"active-class":"border","link":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-title',[_vm._v(_vm._s(title))])],1)}),1)],1),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.logout}},[_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-export")])],1)],1),_c('v-navigation-drawer',{attrs:{"app":"","dark":"","color":"#424242","width":"300"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{staticClass:"mt-5"},[_c('v-avatar',[_c('v-img',{attrs:{"src":"https://randomuser.me/api/portraits/thumb/men/75.jpg"}})],1),_c('p',{staticClass:"white--text subheading mt-1 text-center"},[_vm._v(" "+_vm._s(_vm.user.userEmail)+" ")])],1),_c('v-flex',{staticClass:"mt-4 mb-4"},[_c('v-divider',{attrs:{"color":"white"}})],1)],1),_c('v-list',{staticClass:"text-left"},[_c('v-list-item',{attrs:{"to":"/dashboard"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Dashboard")])],1)],1),_c('v-list-item',{attrs:{"to":"/powerguard/discussions"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Keskustelut")])],1)],1),_c('v-list-item',{attrs:{"to":"/powerguard/show-survey-form"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Tee Kysely")])],1)],1),_c('v-list-item',{attrs:{"to":"/powerguard/add-feedback"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Palaute")])],1)],1),_c('v-list-group',{attrs:{"value":false,"prepend-icon":"mdi-account-circle"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Kysymykset")])]},proxy:true}])},_vm._l((_vm.questionLinks),function(ref,i){
var title = ref[0];
var icon = ref[1];
var route = ref[2];
return _c('v-list-item',{key:i,attrs:{"link":"","to":route,"sub-group":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1)],1)}),1),_c('v-list-group',{attrs:{"value":false,"prepend-icon":"mdi-office-building"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Kyselyt")])]},proxy:true}])},_vm._l((_vm.queries),function(ref,i){
var title = ref[0];
var icon = ref[1];
var route = ref[2];
return _c('v-list-item',{key:i,attrs:{"link":"","to":route,"sub-group":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1)],1)}),1),_c('v-list-group',{attrs:{"value":false,"prepend-icon":"mdi-office-building"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Materiaalipankki")])]},proxy:true}])},_vm._l((_vm.materialBank),function(ref,i){
var title = ref[0];
var icon = ref[1];
var route = ref[2];
return _c('v-list-item',{key:i,attrs:{"link":"","to":route,"sub-group":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1)],1)}),1),_c('v-list-group',{attrs:{"value":false,"prepend-icon":"mdi-office-building"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Keskustelut")])]},proxy:true}])},_vm._l((_vm.discussions),function(ref,i){
var title = ref[0];
var icon = ref[1];
var route = ref[2];
return _c('v-list-item',{key:i,attrs:{"link":"","to":route,"sub-group":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }