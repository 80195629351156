<!-- Register template -->
<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12
                ><h2>{{ $t('register.title') }}</h2></v-flex
              >
              <v-flex xs12 md12>
                <v-text-field
                  :label="$t('register.email')"
                  prepend-icon="mdi-email"
                  :rules="[rules.required, rules.email]"
                  v-model="registerValues.userEmail"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('register.password')"
                  prepend-icon="mdi-lock"
                  :rules="[rules.required]"
                  v-model="registerValues.userPassword"
                  type="password"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('register.password_repeat')"
                  prepend-icon="mdi-lock"
                  :rules="[rules.required]"
                  v-model="userPasswordRepeat"
                  type="password"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('register.firstName')"
                  prepend-icon="mdi-account"
                  :rules="[rules.required]"
                  v-model="registerValues.userFirstName"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('register.lastName')"
                  prepend-icon="mdi-account"
                  :rules="[rules.required]"
                  v-model="registerValues.userLastName"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('register.phoneNumber')"
                  prepend-icon="mdi-cellphone "
                  :rules="[rules.required]"
                  v-model="registerValues.userPhoneNumber"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('register.jobTitle')"
                  prepend-icon="mdi-account-tie "
                  :rules="[rules.required]"
                  v-model="registerValues.userJobTitle"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-account-supervisor"
                  v-model="registerValues.userRoles"
                  :items="roles"
                  :label="$t('register.roles')"
                  placeholder="Valitse..."
                  item-text="name"
                  item-value="value"
                  :rules="[rules.required]"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="registerValues.userState"
                  :items="userState"
                  item-text="name"
                  item-value="value"
                  :label="$t('register.state')"
                  placeholder="Valitse..."
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  :label="$t('register.description')"
                  prepend-icon="mdi-information-variant"
                  v-model="registerValues.userDescription"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  :label="$t('register.information')"
                  prepend-icon="mdi-comment-text-outline"
                  v-model="registerValues.userInformation"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="register"
                >
                  {{ $t('register.register-button') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService.js';
export default {
  data() {
    return {
      roles: [
        { name: this.$t('register.roles-user'), value: 'user' },
        { name: this.$t('register.roles-admin'), value: 'admin' },
        { name: this.$t('register.roles-super-admin'), value: 'super-admin' },
      ],
      userState: [
        { name: this.$t('register.state-active'), value: 'active' },
        { name: this.$t('register.state-passive'), value: 'passive' },
      ],

      registerValues: {},
      userPasswordRepeat: '',
      message: null,
      error: null,
      rules: {
        required: (value) => !!value || this.$t('register.required'),
        email: (value) => {
          const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          return pattern.test(value) || this.$t('register.invalid-email');
        },
      },
    };
  },
  methods: {
    //Check if given password and passwordRepeat ase same
    valid() {
      return this.registerValues.userPassword === this.userPasswordRepeat;
    },
    //Register button is clicked
    async register() {
      this.registerValues.userOrganization = 1;

      try {
        const response = await AuthenticationService.register(
          this.registerValues
        );

        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
