import Api from '@/services/Api';
import store from '@/store';
//User api cals
export default {
  getDiscussionCommentsByDiscussionId(params) {
    return Api().get('/powerguard/discussions-comments', {
      params,
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  getDiscussionCommentsByCommentId(params) {
    return Api().get('/powerguard/discussions-comment-id', {
      params,
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  //   getAllDiscussions() {
  //     return Api().get('/powerguard/discussions/', {
  //       headers: {
  //         Authorization: 'Bearer ' + store.getters.getToken,
  //       },
  //     });
  //   },
  //   saveQueryModification(credintials) {
  //     return Api().patch('/powerguard/query', credintials, {
  //       headers: {
  //         Authorization: 'Bearer ' + store.getters.getToken,
  //       },
  //     });
  //   },
  addNewComment(credintials) {
    return Api().post('/powerguard/discussion/comment', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  //   getQueryByCustomerId(credintials) {
  //     return Api().post('/powerguard/query-customer-id/', credintials, {
  //       headers: {
  //         Authorization: 'Bearer ' + store.getters.getToken,
  //       },
  //     });
  //   },
};
