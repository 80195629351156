<template>
  <!-- Show user data-table -->
  <v-data-table
    :headers="headers"
    :items="users"
    sort-by="users"
    class="elevation-1"
  >
    <!-- Display success and error messages -->
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Käyttäjät</v-toolbar-title>

        <v-spacer></v-spacer>
        <!-- Edit user modal -->
        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.userEmail"
                      :label="$t('editUser.userEmail')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.userFirstName"
                      :label="$t('editUser.userFirstName')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.userLastName"
                      :label="$t('editUser.userLastName')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.userPhoneNumber"
                      :label="$t('editUser.userPhoneNumber')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.userJobTitle"
                      :label="$t('editUser.userJobTitle')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-account-supervisor"
                      v-model="editedItem.userOrganization"
                      :items="users"
                      :label="$t('editUser.userOrganization')"
                      placeholder="Valitse..."
                      item-text="Organization.organizationName"
                      item-value="Organization.id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-account-supervisor"
                      v-model="editedItem.userRoles"
                      :items="roles"
                      :label="$t('editUser.userRoles')"
                      placeholder="Valitse..."
                      item-text="name"
                      item-value="value"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-account-supervisor"
                      v-model="editedItem.userState"
                      :items="states"
                      :label="$t('editUser.userState')"
                      placeholder="Valitse..."
                      item-text="name"
                      item-value="value"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editedItem.userInformation"
                      :label="$t('editUser.userInformation')"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      :label="$t('editUser.userDescription')"
                      v-model="editedItem.userDescription"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="Sulje">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="Talleta">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import UserService from '@/services/UserService.js';

export default {
  data() {
    return {
      users: [],
      dialog: false,
      error: null,
      message: null,
      formTitle: 'Muokkaa',
      headers: [
        { text: this.$t('showUsers.userEmail'), value: 'userEmail' },
        { text: this.$t('showUsers.userFirstName'), value: 'userFirstName' },
        { text: this.$t('showUsers.userLastName'), value: 'userLastName' },
        {
          text: this.$t('showUsers.userPhoneNumber'),
          value: 'userPhoneNumber',
        },
        { text: this.$t('showUsers.userJobTitle'), value: 'userJobTitle' },
        { text: this.$t('showUsers.userRoles'), value: 'userRoles' },
        { text: this.$t('showUsers.userState'), value: 'userState' },
        {
          text: this.$t('showUsers.userActions'),
          value: 'actions',
          sortable: false,
        },
      ],
      roles: [
        { name: 'Käyttäjä', value: 'user' },
        { name: 'Pääkäyttäjä', value: 'admin' },
        { name: 'Super admin', value: 'super-admin' },
      ],
      states: [
        { name: this.$t('showUsers.userStateItems.active'), value: 'active' },
        { name: this.$t('showUsers.userStateItems.passive'), value: 'passive' },
      ],
      editedItem: {
        userEmail: '',
        userFirstName: '',
        userLastName: '',
        userPhoneNumber: '',
        userJobTitle: '',
        userRoles: '',
        userState: '',
        userOrganization: '',
        userInformation: '',
        userId: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  /**
   * Show user when page is loaded
   */
  async created() {
    const response = await UserService.getAllUsers();
    this.users = response.data.users;
  },
  //Edit pencil is pressed
  methods: {
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    // Close modal
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.users);
      });
    },
    /**
     * Is save button is pressed. Save modification information of users to database
     */
    async save() {
      try {
        const response = await UserService.saveUserModification(
          this.editedItem
        );
        this.message = response.data.message;
        this.error = response.data.error;
        const users = await UserService.getAllUsers();
        this.users = users.data.users;
        this.close();
        setTimeout(() => {
          this.message = null;
          this.error = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = 'Jokin meni vikaan';
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
<style></style>
