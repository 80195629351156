<template>
  <v-container class="container--fluid grid-list-md text-center">
    <v-row dense class="mb-5">
      <v-col cols="12" sm="6" md="6"> <DemoChart class="mr-10"/></v-col>
      <v-col cols="12" sm="6" md="6"> <TestChart class="mr-10"/></v-col>
    </v-row>
    <v-row dense class="mb-10">
      <v-col cols="12" sm="6" md="6"> <BarChart /></v-col>
      <v-col cols="12" sm="6" md="6"> <TestChart /></v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="12" md="12"> <PieChart /></v-col>
    </v-row>
  </v-container>
</template>

<script>
import TestChart from '@/components/Dashboard/Testi/TestCharts';
import BarChart from '@/components/Dashboard/Testi/BarChart';
import DemoChart from '@/components/Dashboard/Testi/DemoChart';
import PieChart from '@/components/Dashboard/Testi/PieChart';
export default {
  components: {
    TestChart,
    BarChart,
    DemoChart,
    PieChart,
  },
};
</script>

<style></style>
