<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left><h4>Lisää kysely</h4></v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  label="Otsikko"
                  prepend-icon="mdi-account"
                  :rules="[rules.required]"
                  v-model="addQueryValues.queryTitle"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  label="Kuvaus kyselystä"
                  prepend-icon="mdi-information-variant"
                  v-model="addQueryValues.queryDescription"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addQueryValues.queryOrganization"
                  :items="organizations"
                  item-text="organizationName"
                  item-value="id"
                  label="Organisaatio"
                  placeholder="Valitse"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addQueryValues.queryQuestions"
                  :items="questions"
                  item-text="questionDescription"
                  item-value="id"
                  label="Valitse kysymykset"
                  placeholder="Valitse"
                  required
                  multiple
                  chips
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addQueryValues.queryState"
                  :items="queryState"
                  item-text="name"
                  item-value="value"
                  label="Kyselyn tila"
                  placeholder="Valitse"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewQuery"
                >
                  Lisää uusi kysely
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import QueryService from '@/services/PowerGuard/QueryService.js';
import OrganizationService from '@/services/OrganizationService.js';
import QuestionService from '@/services/PowerGuard/QuestionService.js';
export default {
  data() {
    return {
      queryState: [
        { name: 'Avoin', value: 'Avoin' },
        { name: 'Suljettu', value: 'Suljettu' },
      ],
      organizations: [],
      questions: [],
      addQueryValues: {},
      message: null,
      error: null,
      rules: {
        required: (value) => !!value || 'Pakollinen tieto',
      },
    };
  },
  async created() {
    try {
      const response = await OrganizationService.getAllOrganizations();
      this.organizations = response.data.organizations;
    } catch (error) {
      this.error = error;
    }
    try {
      const response = await QuestionService.getAllQuestions();
      this.questions = response.data.questions;
    } catch (error) {
      this.error = error;
    }
  },

  methods: {
    async addNewQuery() {
      try {
        console.log(this.addQueryValues);
        let question = '';
        let editQuestion;
        for (let i = 0; i < this.addQueryValues.queryQuestions.length; i++) {
          question += this.addQueryValues.queryQuestions[i] + ',';
        }
        editQuestion = question.slice(0, -1);
        this.addQueryValues.queryQuestions = editQuestion;
        const response = await QueryService.addNewQuery(this.addQueryValues);
        this.addQueryValues = {};
        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
