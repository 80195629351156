import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Vuetify from 'vuetify';
import './plugins/vuetify.js';
import store from './store';
//import Axios from 'axios';
import i18n from './i18n';
import { sync } from 'vuex-router-sync';

//import colors from 'vuetify/lib/util/colors';
Vue.config.productionTip = false;
sync(store, router);
// set auth header
//Axios.defaults.headers.common['Authorization'] = `token ${store.state.token}`;
new Vue({
  vuetify: new Vuetify({
    theme: {
      primary: '#f44336',
      secondary: '#1c7514',
      accent: '#9c27b0',
      error: '#f44336',
      warning: '#ffeb3b',
      info: '#2196f3',
      success: '#4caf50',
    },
  }),

  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
