import Api from '@/services/Api';
import store from '@/store';
//User api cals
export default {
  getAllUsers() {
    return Api().get('/users', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  saveUserModification(credintials) {
    return Api().patch('/users/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};

// AuthenticationService.register({
//     email:'testi@testi.fi',
//     passsword: '1234455'
// })
