<template>
  <v-data-table
    :headers="headers"
    :items="queries"
    sort-by="queries"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Kyselyt</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      label="Otsikko"
                      prepend-icon="mdi-account"
                      v-model="queryItems.queryTitle"
                      autocomplete="off"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      label="Kyselyn kuvaus"
                      prepend-icon="mdi-information-variant"
                      v-model="queryItems.queryDescription"
                      autocomplete="off"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      prepend-icon="mdi-account-supervisor"
                      v-model="queryItems.queryState"
                      :items="queryStates"
                      label="Kyselyn tila"
                      :placeholder="$t('editGroup.groupAutoCompleteChoise')"
                      item-text="name"
                      item-value="value"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:[`item.results`]="{ item }">
      <v-icon small class="mr-2" @click="showResults(item)">
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import QueryService from '@/services/PowerGuard/QueryService.js';

export default {
  data() {
    return {
      queries: [],
      dialog: false,
      error: null,
      message: null,
      formTitle: 'Muokkaa käyttäjän tietoja',
      headers: [
        { text: 'Organisaatio', value: 'queryOrganization' },
        { text: 'Otsikko', value: 'queryTitle' },
        { text: 'Kuvaus', value: 'queryDescription' },
        { text: 'Tila', value: 'queryState' },
        {
          text: 'Muuta',
          value: 'actions',
          sortable: false,
        },
        {
          text: 'Tulokset',
          value: 'results',
          sortable: false,
        },
      ],
      queryStates: [
        { name: 'Avoin', value: 'Avoin' },
        {
          name: 'Suljettu',
          value: 'Suljettu',
        },
      ],
      queryItems: {
        groupName: '',
        groupState: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  async created() {
    const response = await QueryService.getAllQueries();
    this.queries = response.data.queries;
  },

  methods: {
    editItem(item) {
      this.queryItems = Object.assign({}, item);
      this.dialog = true;
    },

    showResults(item) {
      this.$router.push({
        name: 'show-survey-results',
        params: { queryId: item.queryId },
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.queryItems = Object.assign({}, this.groups);
      });
    },

    async save() {
      try {
        const response = await QueryService.saveQueryModification(
          this.queryItems
        );
        this.message = response.data.message;
        this.error = response.data.error;
        const queries = await QueryService.getAllQueries();
        this.queries = queries.data.queries;
        this.close();
        setTimeout(() => {
          this.message = null;
          this.error = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = 'Jokin meni vikaan';
        setTimeout(() => {
          this.error = null;
        });
      }
    },
  },
};
</script>
<style></style>
