<!-- Main view when user is logged in -->
<template>
  <v-app>
    <!-- <Navbar /> -->
    <NavBarMain />

    <v-main class="ma-4">
      <Products />
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
//import Navbar from '@/components/main/NavBar';
import Footer from '@/components/Main/Footer';
import NavBarMain from '@/components/Main/NavBarMain';
import Products from '@/components/Main/Products.vue';
export default {
  data() {
    return {
      username: '',
    };
  },
  components: {
    //  Navbar,
    Footer,
    Products,
    NavBarMain,
  },
  async created() {
    // if (!this.$store.getters.isLoggedIn) {
    //  this.$router.push('/login');
    // }
    this.username = this.$store.getters.getUser.username;
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
};
</script>
