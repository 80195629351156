<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card :elevation="hover ? 24 : 6" class="mb-4 pa-2">
              <v-card-title class="headline">
                {{ subComments[0].DiscussionComment.discussionComment }}
              </v-card-title>

              <v-card-subtitle></v-card-subtitle>

              <v-card-actions>
                <v-btn @click="addNewDiscussionSubComment()">
                  Lisää kommentti
                </v-btn>

                <v-btn @click="BackToComments()">
                  Takaisin
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" v-for="(comment, index) in subComments" :key="index">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card :elevation="hover ? 24 : 6" class="mb-4 pa-2">
              <v-card-title>
                <v-avatar color="red" size="40">
                  <span class="white--text ">{{
                    showNameLetters(
                      comment.User.userFirstName,
                      comment.User.userLastName
                    )
                  }}</span>
                </v-avatar>
                <span class="title">{{
                  showDateAndTime(comment.createdAt)
                }}</span>
              </v-card-title>

              <v-card-text class="headline">
                {{ comment.discussionSubComment }}
              </v-card-text>

              <v-card-actions>
                <v-list-item class="grow">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      showFirtsNameLastName(
                        comment.User.userFirstName,
                        comment.User.userLastName
                      )
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DiscussionCommentSubService from '@/services/PowerGuard/DiscussionCommentSubService';

import moment from 'moment';
export default {
  data() {
    return {
      subComments: [
        {
          id: '',
          discussionDescription: '',
          discussionId: '',
          discussionTitle: '',
          createdAt: '',
          discussionComment: '',
          DiscussionComment: [
            {
              discussionComment: '',
            },
          ],
          User: [
            {
              userLastName: '',
              userFirstName: '',
            },
          ],
        },
      ],
      item: '',
    };
  },
  async mounted() {
    try {
      const discussionSubComments = await DiscussionCommentSubService.getDiscussionSubCommentsByCommentsId(
        {
          discussionCommentId: this.$store.getters.getDiscussionCommentId,
        }
      );
      this.subComments = discussionSubComments.data.subComments;
    } catch (error) {
      this.error = error;
    }
  },
  methods: {
    showDateAndTime(dateTime) {
      return moment(dateTime).format('DD.MM.YYYY');
    },
    showNameLetters(firstName, lastName) {
      if (firstName && lastName) {
        return firstName.substring(0, 1) + lastName.substring(0, 1);
      }
    },
    showFirtsNameLastName(firstName, lastName) {
      return firstName + ' ' + lastName;
    },
    showSubComments(id) {
      this.$store.dispatch('setDiscussionCommentId', id);
      this.$router.push({
        name: 'powerguard/sub-comments',
      });
    },

    addNewDiscussionSubComment() {
      this.$router.push({
        name: 'powerguard/add-new-sub-comment',
        // params: { discussionId: this.discussion.discussionId },
      });
    },
    BackToComments() {
      this.$router.push({
        name: 'powerguard/discussions',
      });
    },
  },
};
</script>

<style></style>
