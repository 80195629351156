<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th>Kysymykset</th>
          <th
            class="text-left"
            v-for="(question, index) in questions"
            :key="index"
          >
            {{ `Vastaus: ` + (index + 1) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(question, index) in questions" :key="index">
          <td>{{ question.questionDescription }}</td>
          <td v-for="(result, index) in results" :key="index"></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import QueryResultService from '@/services/PowerGuard/QueryResultService';
export default {
  data() {
    return {
      questions: [],
      results: [],
      query: {},
    };
  },
  async created() {
    const response = await QueryResultService.getQueryResultsByQueryId({
      queryId: this.$route.params.queryId,
    });
    this.questions = response.data.questions;
    this.results = response.data.queryResults;
    this.query = response.data.query;
  },
};
</script>

<style></style>
